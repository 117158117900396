import React from "react";
import { graphql } from "gatsby";
import g from "glamorous";
import { css } from "glamor";
import Layout from "../components/layout";
import ContactSidebar from "../components/ContactSidebar";
import AdresseSidebar from "../components/AdresseSidebar";
import AvantApresBlock from "../components/AvantApresBlock";
import KeybodyBlock from "../components/KeybodyBlock";
import VideoBlock from "../components/VideoBlock";
import { FaPlus } from "react-icons/fa";

import Menu from "../components/Menu";
import Footer from "../components/Footer";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { Container, Row, Col } from "glamorous-grid";
import { Helmet } from "react-helmet";
import { FaHome } from "react-icons/fa";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const {
    portraitKron,
    portraitFossat,
    portraitRihame,
    portraitLaura,
    portraitYlan,
    portraitMarie,
    elodie,
    emilie,
    isabelle,
  } = data; // data.markdownRemark holds our post data

  const Wrapper = g.div({
    maxWidth: `1100px`,
    margin: `0 auto 2.5em`,
  });

  const Title = g.h1({
    textAlign: `center`,
    fontSize: `38px`,
    color: `#3c4d68`,
    maxWidth: `700px`,
    margin: `1.5em auto 1em`,
    fontVariant: `none`,
    fontWeight: `600`,
    letterSpacing: `.01em`,
    lineHeight: `49px`,
    "@media(max-width: 767px)": {
      fontSize: `30px`,
      lineHeight: `32px`,
    },
  });

  const H2 = g.h2({
    fontSize: `24px`,
    fontWeight: `500`,
    lineHeight: `30px`,
    marginTop: `0`,
    "@media(max-width: 767px)": {
      fontSize: `20px`,
      lineHeight: `25px`,
    },
  });

  const LinkWrapper = g.div({
    textAlign: `right`,
  });

  const LinkPlus = css({
    color: `#3c4d68`,
    textDecoration: `none`,
    border: `none`,
    width: `100%`,
  });

  const cssPlus = css({
    fontSize: `1em`,
    padding: `0 .4em .12em`,
  });

  const Breadcrumb = g.ul({
    fontSize: `13px`,
    lineHeight: `20px`,
    marginLeft: `0 !important`,
    marginTop: `0`,
    marginBottom: `10px !important`,
    fontWeight: `500`,
    letterSpacing: `.015em`,
  });

  const BreadcrumbItem = g.li({
    display: `inline`,
    "&:first-of-type": {
      "&::before": {
        content: `none`,
      },
    },
    "&::before": {
      content: `/`,
      padding: `0 .5em`,
    },
  });

  const cssBreadcrumbLink = css({
    color: `#3c4d68`,
    borderBottom: `none`,
  });

  return (
    <Layout>
      <g.Div id="page-wrap" margin={`0 auto`} backgroundColor={`#fcfcfc`}>
        <Helmet>
          <title>L'équipe de cabinet médical à Paris | Dr Cédric Kron</title>
          <meta
            name="description"
            content="Le cabinet du Dr Cédric Kron est un espace dédié à l'obtention d'un résultat naturel grace aux apports des techniques de médecine et de chirurgie esthétique."
          />
          <html lang="fr" />
          <link
            rel="alternate"
            hreflang="en"
            href="https://www.dr-kron.com/en/medical-team/"
          />
          <script type="application/ld+json">{` {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "L'équipe de cabinet médical",
          "item": "https://www.dr-kron.com/equipe-cabinet/"
          }]
              }
    `}</script>
        </Helmet>
        <Menu current="equipe-cabinet" pathEn="/en/medical-team/" />
        <Wrapper>
          <Container css={{ padding: `0 15px !important` }}>
            <Row>
              <Col>
                <div className="page-post">
                  <Title>L'équipe du cabinet médical</Title>
                </div>
              </Col>
            </Row>

            <Row>
              <Col span={{ xs: 12 / 12, lg: 69 / 100 }}>
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to="/" {...cssBreadcrumbLink} title="Page d'accueil">
                      <FaHome
                        css={{
                          fontSize: `1.2em`,
                          paddingBottom: `.2em`,
                          transform: `translateY(5px)`,
                        }}
                      />
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>L'équipe de cabinet médical</BreadcrumbItem>
                </Breadcrumb>
                <p className="intro">
                  Le cabinet du Docteur Cédric Kron est un espace dédié à la
                  prise en charge globale du corps et à l'obtention d'un
                  résultat naturel grace aux apports combinés des techniques de
                  médecine et de chirurgie esthétique. Vous serez prise en
                  charge par une équipe pluridisciplinaire centrée sur
                  l'expertise du Dr&nbsp;Kron.
                </p>

                <Row css={{ marginBottom: `30px` }}>
                  <Col span={{ xs: 3 / 12 }}>
                    <Img
                      fluid={portraitKron.childImageSharp.fluid}
                      alt="Dr Cédric Kron, chirurgien esthétique à Paris"
                    />
                  </Col>

                  <Col span={{ xs: 9 / 12 }}>
                    <H2>Dr Cedric Kron - Chirurgien plasticien</H2>

                    <p css={{ marginBottom: `.1em` }}>
                      Ancien interne Médaille d'or de Chirurgie des Hôpitaux de
                      Paris, Ancien Chef de Clinique-Assistant des Hôpitaux de
                      Paris, le Dr Kron est{" "}
                      <Link to="/chirurgien-esthetique-paris/">
                        qualifié en Chirurgie Plastique, Reconstructrice et
                        Esthétique
                      </Link>
                      . Sa pratique de chirurgien et de médecin esthétique
                      s'attache à développer les techniques très peu invasives
                      qui répondent le plus fidèlement possible au désir de ses
                      patients.
                    </p>
                    <LinkWrapper>
                      <Link to="/chirurgien-esthetique-paris/" {...LinkPlus}>
                        <FaPlus {...cssPlus} />
                        En savoir plus
                      </Link>
                    </LinkWrapper>
                  </Col>
                </Row>
                <Row css={{ marginBottom: `30px` }}>
                  <Col span={{ xs: 3 / 12 }}>
                    <Img
                      fluid={portraitFossat.childImageSharp.fluid}
                      alt="Dr Sébastien Fossat - Chirurgien esthétique à Paris (75) et Saint-Germain-en-Laye (78)"
                    />
                  </Col>

                  <Col span={{ xs: 9 / 12 }}>
                    <H2>Dr Sébastien Fossat - Chirurgien plasticien</H2>

                    <p>
                      Diplômé en Chirurgie Plastique, Reconstructrice et
                      Esthétique depuis 2004, le Dr Fossat a partagé pendant 10
                      ans son activité entre reconstruction et chirurgie
                      esthétique en tant qu’Adjoint au Chef du Service de
                      Chirurgie Plastique de l’Hôpital Percy. Exerçant désormais
                      en secteur privé, il propose des consultations de
                      chirurgie plastique dans son{" "}
                      <a
                        href="http://drfossat.fr"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        cabinet de Saint-Germain-en-Laye
                      </a>{" "}
                      ainsi qu’à Paris 17e dans le cadre d’une collaboration
                      avec le Dr Kron.
                    </p>
                  </Col>
                </Row>
                <Row css={{ marginBottom: `30px` }}>
                  <Col span={{ xs: 3 / 12 }}>
                    <Img
                      fluid={isabelle.childImageSharp.fluid}
                      alt="Isabelle, aide-opératoire du Dr Cédric Kron"
                    />
                  </Col>
                  <Col span={{ xs: 9 / 12 }}>
                    <H2>Isabelle - Aide-opératoire</H2>
                    <p>
                      Infirmière de Bloc Opératoire Diplômée d'état, spécialiste
                      de la chirurgie Chirurgie Plastique, Reconstructrice et
                      esthétique, Isabelle assiste le Dr&nbsp;Cédric Kron depuis
                      plus de 10 ans pour toutes les interventions chirurgicales
                      qu'il réalise dans les cliniques parisiennes.
                    </p>
                  </Col>
                </Row>
                <Row css={{ marginBottom: `30px` }}>
                  <Col span={{ xs: 3 / 12 }}>
                    <Img
                      fluid={portraitRihame.childImageSharp.fluid}
                      alt="Rihame - Directrice du cabinet du Dr Kron"
                    />
                  </Col>

                  <Col span={{ xs: 9 / 12 }}>
                    <H2>Rihame - Directrice du cabinet</H2>

                    <p>
                      Assistante médicale personnelle du Dr Kron depuis de
                      nombreuses années, Rihame répondra à toutes vos questions
                      en tant qu'interlocutrice privilégiée et coordonnera toute
                      l'équipe autour de vos attentes. Elle vous accompagnera
                      également dans le cadre des{" "}
                      <Link to="/visage/consultation-diagnostic/">
                        pré-consultations diagnostic visage
                      </Link>{" "}
                      proposées au cabinet.
                    </p>
                  </Col>
                </Row>

                <Row css={{ marginBottom: `30px` }}>
                  <Col span={{ xs: 3 / 12 }}>
                    <Img
                      fluid={portraitLaura.childImageSharp.fluid}
                      alt="Laura - Infirmière diplômée d’état (IDE) - Responsable des soins au cabinet de Paris 17"
                    />
                  </Col>

                  <Col span={{ xs: 9 / 12 }}>
                    <H2>
                      Laura - Infirmière diplômée d’état (IDE) - Responsable des
                      soins
                    </H2>

                    <p>
                      Infirmière diplômée d'état, Laura prend en charge avec le
                      Dr Kron les soins post-opératoires. Diplômée de{" "}
                      <Link to="/silhouette/cryolipolyse-coolsculpting/">
                        Coolsculpting
                      </Link>{" "}
                      et <Link to="/silhouette/emsculpt/">EMSculpt</Link>, elle
                      vous aidera à déterminer quel traitement est le plus
                      adapté pour vous dans le cadre de pré-consultations
                      diagnostic silhouette. Laura est également formée à
                      l'ensemble des techniques de peeling, laser,
                      radiofréquence et mésothérapie proposées au sein du
                      cabinet. 
                    </p>
                  </Col>
                </Row>

                <Row css={{ marginBottom: `30px` }}>
                  <Col span={{ xs: 3 / 12 }}>
                    <Img
                      fluid={elodie.childImageSharp.fluid}
                      alt="Élodie - Assistante médicale à Paris"
                    />
                  </Col>

                  <Col span={{ xs: 9 / 12 }}>
                    <H2>Élodie - Assistante médicale</H2>

                    <p>
                      Elodie répondra à toutes vos questions sur les traitements
                      de chirurgie et de médecine esthétiques proposés au
                      cabinet. Formée aux outils de diagnostic du vieillissement
                      du visage Visia Complexion Analysis et Skinscope LED ainsi
                      qu’au protocole{" "}
                      <Link to="/visage/universkin/">Universkin</Link>, elle
                      vous accompagnera dans le cadre de la prise en charge
                      sur-mesure de votre peau. Élodie a également un diplôme
                      d’esthéticienne et se charge de l’animation des réseaux
                      sociaux du cabinet (
                      <a
                        href="https://www.instagram.com/docteurkron/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Instagram
                      </a>
                      ,{" "}
                      <a
                        href="https://www.facebook.com/DrCedricKron"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Facebook
                      </a>
                      ).
                    </p>
                  </Col>
                </Row>

                <Row css={{ marginBottom: `30px` }}>
                  <Col span={{ xs: 3 / 12 }}>
                    <Img
                      fluid={portraitMarie.childImageSharp.fluid}
                      alt="Marie, Infirmière diplômée d’état (IDE) au cabinet du Dr Cédric Kron"
                    />
                  </Col>

                  <Col span={{ xs: 9 / 12 }}>
                    <H2>Marie – Infirmière diplômée d’état (IDE)</H2>

                    <p>
                      Infirmière diplômée d'état, Marie prend en charge avec le
                      Dr Kron les soins post-opératoires. Diplômée de
                      Coolsculpting et EMSculpt, elle vous aidera à déterminer
                      quel traitement est le plus adapté pour vous dans le cadre
                      de pré-consultations{" "}
                      <Link to="/silhouette/consultation-diagnostic/">
                        diagnostic silhouette
                      </Link>
                      . Marie est également formée à l'ensemble des techniques
                      de peeling, laser, radiofréquence et mésothérapie
                      proposées au sein du cabinet.
                    </p>
                  </Col>
                </Row>

                <Row css={{ marginBottom: `30px` }}>
                  <Col span={{ xs: 3 / 12 }}>
                    <Img
                      fluid={emilie.childImageSharp.fluid}
                      alt="Émilie - Assistante médicale & coach sportif"
                    />
                  </Col>

                  <Col span={{ xs: 9 / 12 }}>
                    <H2>Émilie - Assistante médicale & coach sportif</H2>

                    <p>
                      Assistante médicale et Coach sportif spécialiste des
                      dispositifs{" "}
                      <Link to="/xbody-sculpter-raffermir-son-corps/">
                        XBody
                      </Link>{" "}
                      et <Link to="/silhouette/emsculpt/">EMSculpt</Link> ainsi
                      que du protocole de remodelage de l’architecture du corps
                      Keybody, Émilie accompagne les patients dans la prise en
                      charge sur mesure de leur corps, que ce soit sur les plans
                      musculaire, cardio-vasculaire et respiratoire. Elle
                      répondra à toutes vos questions dans le cadre de
                      pré-consultations diagnostic silhouette. Elle supervise
                      les séances d’entrainement XBody les mercredi et vendredi.
                    </p>
                  </Col>
                </Row>

                <Row css={{ marginBottom: `30px` }}>
                  <Col span={{ xs: 3 / 12 }}>
                    <Img
                      fluid={portraitYlan.childImageSharp.fluid}
                      alt="Ylan, coach Sportif spécialiste de XBody à Paris"
                    />
                  </Col>

                  <Col span={{ xs: 9 / 12 }}>
                    <H2>Ylan – Coach Sportif</H2>

                    <p>
                      Coach sportif, Ylan est spécialiste du dispositif d'
                      <Link to="/xbody-sculpter-raffermir-son-corps/">
                        électromyostimulation musculaire assistée XBody
                      </Link>
                      .
                    </p>
                    <p>
                      Il supervise les séances d’entrainement XBody organisées
                      au cabinet les mardi et jeudi.
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={{ xs: 12 / 12, lg: 31 / 100 }}>
                <ContactSidebar about="le cabinet" />
                <AdresseSidebar />
                <AvantApresBlock />
                <KeybodyBlock />
                <VideoBlock />
              </Col>
            </Row>
          </Container>
        </Wrapper>
        <Footer />
      </g.Div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query Equipe {
    portraitKron: file(relativePath: { eq: "images/portrait-dr-kron.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    portraitFossat: file(
      relativePath: {
        eq: "images/dr-sebastien-fossat-chirurgien-esthetique.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    portraitRihame: file(relativePath: { eq: "images/rihame-kharrat.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    portraitLaura: file(
      relativePath: { eq: "images/laura-courbon-infirmiere-etat.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    portraitMarie: file(
      relativePath: { eq: "images/marie-infirmiere-diplomee-etat.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    portraitYlan: file(relativePath: { eq: "images/ylan-coach-sportif.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    elodie: file(relativePath: { eq: "images/elodie.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    emilie: file(
      relativePath: {
        eq: "images/emilie-coach-sportif-assistante-medicale.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    isabelle: file(
      relativePath: { eq: "images/isabelle-aide-operatoire.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
